<template>
    <div class="CheckDiffDetail" style="background-color: #f2f2f2; margin-top: 8px">
        <el-card shadow="never">
            <el-page-header @back="goBack" />
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <el-form ref="form" label-width="80px" width="auto" size="small">
                <el-col :span="6">
                    <el-form-item label="盘点编号">
                        <span>{{ form.bizCode }}</span>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="机构类型">
                        <el-input v-model="form.deptTypeName" readonly />
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="机构名称">
                        <el-input v-model="form.deptName" readonly />
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="创建人">
                        <el-input v-model="form.creator" readonly />
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="创建时间">
                        <el-input v-model="form.createTime" readonly />
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="提交时间">
                        <el-input v-model="form.updateTime" readonly />
                    </el-form-item>
                </el-col>
                <el-row />
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="备注-盘点单" label-width="100px">
                            <el-input
                                readonly
                                v-model="form.remark"
                                type="textarea"
                                style="width: 450px"
                                :maxlength="200"
                                :minlength="1"
                                show-word-limit
                                placeholder="请输入盘点单备注"
                            />
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="备注-盘点" label-width="100px">
                            <el-input
                                readonly
                                v-model="form.checkRemark"
                                type="textarea"
                                style="width: 650px"
                                :maxlength="200"
                                :minlength="1"
                                show-word-limit
                                placeholder="请输入盘点备注"
                            />
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <el-table
                border
                stripe
                style="width: 100%"
                :data="form.details"
                size="mini"
                show-summary
                :summary-method="getSummaries"
                :highlight-current-row="true"
                max-height="440"
                v-loading="loading"
            >
                <el-table-column label="序号" type="index" width="60" align="center" fixed="left" />
                <el-table-column label="商品名称" width="180">
                    <template slot-scope="scope">
                        {{ skuByGoodsCode(scope.row.goodsCode).name }}
                    </template>
                </el-table-column>
                <el-table-column prop="sku.bars" label="条码" width="120">
                    <template slot-scope="scope">
                        <div v-for="b in skuByGoodsCode(scope.row.goodsCode).bars" :key="b">{{ b }}</div>
                    </template>
                </el-table-column>
                <el-table-column label="商品类型" width="80">
                    <template slot-scope="scope">
                        {{ skuByGoodsCode(scope.row.goodsCode).type | goodsType }}
                    </template>
                </el-table-column>
                <el-table-column prop="sku.specs" label="规格" width="100">
                    <template slot-scope="scope">
                        {{ skuByGoodsCode(scope.row.goodsCode).specs }}
                    </template>
                </el-table-column>
                <el-table-column prop="sku.category" label="类目" width="100">
                    <template slot-scope="scope">
                        {{ skuByGoodsCode(scope.row.goodsCode).category }}
                    </template>
                </el-table-column>
                <el-table-column prop="sku.unit" label="单位" width="180">
                    <template slot-scope="scope">
                        {{ skuByGoodsCode(scope.row.goodsCode).unit }}
                    </template>
                </el-table-column>
                <el-table-column prop="sku.brand" label="品牌" width="80">
                    <template slot-scope="scope">
                        {{ skuByGoodsCode(scope.row.goodsCode).brand }}
                    </template>
                </el-table-column>
                <el-table-column
                    prop="firstCheckSnapshotCount"
                    label="初盘快照数量"
                    width="150"
                    v-if="hasPrivilege('biz.stock.query')"
                >
                    <template slot-scope="scope">
                        {{ form.firstCheckSnapshotCounts[scope.$index] }}
                    </template>
                </el-table-column>
                <el-table-column prop="firstCheckStockChangeCount" label="初盘期间库存变动" width="170">
                    <template slot="header">
                        <span @click.stop style="display: inline-block">
                            <el-popover placement="top" title="初盘期间库存变动" width="200" trigger="click">
                                <p>包括初盘期间的销售、退货、调拨、内购、报损、报溢、采购</p>
                                <span slot="reference">初盘期间库存变动 <i class="el-icon-info"></i></span>
                            </el-popover>
                        </span>
                    </template>
                    <template slot-scope="scope">
                        {{ form.firstCheckStockChangeCounts[scope.$index] }}
                    </template>
                </el-table-column>
                <el-table-column prop="firstCheckCount" label="初盘数量" width="100">
                    <template slot-scope="scope">
                        {{ form.firstCheckCounts[scope.$index] }}
                    </template>
                </el-table-column>
                <el-table-column
                    prop="snapshotCounts"
                    label="初盘差异"
                    width="100"
                    v-if="hasPrivilege('biz.stock.query')"
                >
                    <template slot-scope="scope">
                        {{ form.firstCheckDiffCounts[scope.$index] }}
                    </template>
                </el-table-column>
                <el-table-column
                    prop="snapshotCounts"
                    label="复盘快照数量"
                    width="120"
                    v-if="hasPrivilege('biz.stock.query')"
                >
                    <template slot-scope="scope">
                        {{
                            form.firstCheckSnapshotCounts[scope.$index] + form.firstCheckStockChangeCounts[scope.$index]
                        }}
                    </template>
                </el-table-column>
                <el-table-column prop="secondCheckStockChangeCount" label="复盘期间库存变动" width="170">
                    <template slot="header">
                        <span @click.stop style="display: inline-block">
                            <el-popover placement="top" title="复盘期间库存变动" width="200" trigger="click">
                                <p>包括初盘期间的销售、退货、调拨、内购、报损、报溢、采购</p>
                                <span slot="reference">复盘期间库存变动 <i class="el-icon-info"></i></span>
                            </el-popover>
                        </span>
                    </template>
                    <template slot-scope="scope">
                        {{ form.secondCheckStockChangeCounts[scope.$index] }}
                    </template>
                </el-table-column>
                <el-table-column prop="secondCheckCount" label="复盘数量" width="100">
                    <template slot-scope="scope">
                        {{ form.secondCheckCounts[scope.$index] }}
                    </template>
                </el-table-column>
                <el-table-column
                    prop="secondCheckDiffCount"
                    label="盈亏数量"
                    width="100"
                    v-if="hasPrivilege('biz.stock.query')"
                >
                    <template slot-scope="scope">
                        {{ form.secondCheckDiffCounts[scope.$index] }}
                    </template>
                </el-table-column>
            </el-table>
        </el-card>
    </div>
</template>
<script>
import StockBizCommon from 'js/StockBizCommon';
export default {
    name: 'CheckDiffDetail',
    props: {
        code: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            form: {
                code: '',
                deptCode: '',
                deptName: '',
                deptTypeName: '',
                remark: '',
                createTime: '',
                updateTime: '',
                creator: '',
                details: [],
                firstCheckSnapshotCounts: [],
                firstCheckCounts: [],
                secondCheckCounts: [],
            },
            detailParamPropertyArr: ['codes', 'goodsCode', 'counts', 'snapshotCounts', 'checkCounts'],
            goodsDetailMap: new Map(),
            loading: true,
        };
    },
    mounted() {
        (async () => {
            //查询数据
            const rst = await this.$efApi.stockCheckDiffApi.detail(this.code);
            this.form = rst;
            // Util.copyProperties(rst, this.form);
            const arr = await StockBizCommon.makeStockBizDetailGoodsAndParams(rst, this.detailParamPropertyArr);
            this.form.details = arr.detailParams;
            this.goodsDetailMap = arr.goodsDetails;
            this.loading = false;
        })();
    },
    methods: {
        goodsByCode(goodsCode) {
            return this.goodsDetailMap.get(goodsCode);
        },
        skuByGoodsCode(goodsCode) {
            return this.goodsByCode(goodsCode).sku;
        },
        getSummaries(param) {
            const sums = [];
            sums[0] = '合计';
            if (this.hasPrivilege('biz.stock.query')) {
                sums[8] = this.sumReduce(this.form.firstCheckSnapshotCounts);
                sums[10] = this.sumReduce(this.form.firstCheckCounts);
                sums[14] = this.sumReduce(this.form.secondCheckCounts);
            } else {
                sums[9] = this.sumReduce(this.form.firstCheckCounts);
                sums[11] = this.sumReduce(this.form.secondCheckCounts);
            }

            return sums;
        },
        sumReduce(values) {
            const value = (values || []).reduce((prev, curr) => {
                return (prev || 0) + (curr || 0);
            }, 0);
            return value;
        },
    },
};
</script>

<style scoped>
.el-input {
    width: auto;
}

.el-form-item {
    width: auto;
}
</style>
